@import "vars";
@media only screen and (max-width : 1200px) {
	.panel-manage-products {
		.sort {
			select {
				width: 130px; } }
		.limit {
			select {
				width: 56px; } } } }

@media only screen and (max-width : 992px) {
	.header-profile-cart-search {
		text-align: right;
		width: 100%; }
	.product-info {
		.tags {
			margin-bottom: 15px; } } }

@media only screen and (max-width: 768px) {
	h2 {
		text-align: center; }
	.header {
		&-logo, &-regim-phone {
			text-align: center;
			margin-bottom: 15px;
			width: 100%; }
		&-phone {
			text-align: center; }
		&-callback {
			a {
				padding-top: 2px;
				padding-bottom: 2px; } }
		&-profile-cart-search {
			width: auto; }
		&-profile-cart {
			margin-bottom: 0; }
		&-cart {
			text-align: center;
			a {
				.fa {
					margin-bottom: 0; }
				.cart-total {
					display: inline-block;
					margin-left: 3px; } } } }

	#main-menu {
		margin-bottom: 15px;
		padding: 10px 0;
		.header-search {
			float: right;
			border: 1px #ccc solid; }
		> div {
			> ul {
				display: none;
				margin: 0;
				padding: 0;
				margin-top: 10px;
				> li {
					ul {
						display: block;
						position: static;
						min-width: auto;
						width: 100%;
						padding-top: 5px;
						padding-bottom: 5px;
						box-shadow: none;
						.fa-angle-down {
							display: none; } } } } } }
	.flycart {
		.col-xs-2, .col-xs-8 {
			width: 100%; }
		.image {
			text-align: center;
			margin-bottom: 7px; }
		&-buttons {
			display: block;
			> div {
				display: block;
				text-align: center;
				&:last-of-type {
					text-align: center;
					margin-top: 15px; } } } }
	.simplecheckout-cart-products {
		.item {
			display: block;
			.product {}
			.total,
			.remove {
				display: inline-block;
				vertical-align: middle; }
			.product {
				width: 100%; }
			.quantity,
			.total {
				width: auto;
				margin-left: 110px;
				text-align: left; }
			.total {
				span {
					display: inline; } } } }
	.slideshow-wrap {
		display: none; }
	.module-product-block {
		.product-card-grid {
			justify-content: center; } }
	img {
		max-width: 100%; }
	.footer-top {
		ul {
			margin-bottom: 15px; } }
	.footer-bottom {
		text-align: center;
		> div > div {
			display: block; }
		.icons {
			margin-top: 7px;
			margin-left: 0; } } }

