@import "fonts";
@import "libs";
@import "vars";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%;
	background-color: #fff; }

body {
	min-width: 320px;
	font-weight: 300;
	position: relative;
	line-height: 1.5;
	font-family: $default-font;
	font-size: 13px;
	color: $default-font-color;
	overflow-x: hidden;
	opacity: 1; }

button,
a {
	outline: none;
	border-radius: 0; }

ul {
	&.list-unstyled,
	&.list-inline {
		list-style: none;
		margin: 0;
		padding: 0; }
	&.list-inline {
		li {
			display: inline-block;
			& + li {
				margin-left: 7px; } } } }

fieldset {
	border: none;
	background: none;
	padding: 0;
	margin: 0; }
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
select,
textarea {
	padding: 2px 10px;
	font-size: 12px;
	outline: none;
	border: none;
	border-radius: 0;
	background: none;
	border: 1px #ccc solid;
	background-color: $white; }
textarea {
	overflow-y: scroll;
	resize: none; }
.form-group {
	&.required {
		label {
			&:after {
				content: '*';
				color: $accent;
				margin-left: 3px; } } }
	label {
		display: block;
		margin-bottom: 3px; }
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="password"],
	textarea {
		display: block;
		width: 100%; }
	& + & {
		margin-top: 10px; } }

h1, h2 {
	font-weight: 300;
	font-size: 26px;
	text-transform: uppercase;
	border-bottom: 1px #ccc solid;
	margin: 0 0 15px 0; }
h2 {
	font-size: 20px;
	> a {
		&:link, &:visited {
			color: $accent;
			text-decoration: underline; }
		&:hover {
			color: $default-font-color; } } }
h3, h4 {
	text-transform: uppercase; }
#content {
	> h1 {
		line-height: 1.2; } }
a {
	color: $link-color;
	cursor: pointer;
	&:link, &:visited {
		color: $link-color; }
	&:hover, &:active {
		color: $link-color-hover; }
	&:active {
		outline: none;
		text-decoration: none; }
	&:hover {
		transition: all .2s; } }
.btn {
	border: none;
	background: none;
	text-decoration: none;
	border-radius: 5px;
	padding: 0 10px;
	font-size: 12px;
	line-height: 25px;
	box-shadow: 0px 1px 4px #ccc;
	transition: all .2s;
	display: inline-block;
	&:hover {
		text-decoration: none; }
	&-default {
		color: $accent;
		background-color: #FFF;
		&:link, &:visited {
			color: $accent; }
		&:hover {
			background-color: $accent;
			color: #fff; } }
	&-primary {
		color: #fff;
		background-color: $accent;
		&:link, &:visited {
			color: #fff;
			background-color: $accent; }
		&:hover {
			background-color: $pink;
			color: $white; } } }

.text-center {
	text-align: center; }
.text-right {
	text-align: right; }
.text-danger {
	color: $accent;
	font-size: 12px; }

.alert {
	margin-bottom: 20px;
	border: 1px #ccc solid;
	padding: 15px 35px 15px 15px;
	position: relative;
	background-color: $white;
	button {
		background: none;
		border: none;
		position: absolute;
		right: 5px;
		top: 5px; }
	&-danger {
		border-color: $accent; } }
.table {
	width: 100%;
	margin-bottom: 20px;
	&.table-bordered {
		td {
			padding: 5px;
			background-color: #fafafa; }
		tr:nth-child(even) {
			td {
				background-color: #ccc; } }
		thead {
			td {
				font-weight: 500; } } } }

.header {
	background-color: $accent;
	color: $white;
	a {
		color: $white;
		&:link, &:visited {
			color: $white; }
		&:hover {
			color: $link-color-hover; } }
	&-wrap {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 15px 0;
		align-items: center; }
	&-regim-phone {
		font-size: 16px;
		> div {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			+ div {
				margin-left: 10px; } }
		.fa {
			font-size: 24px;
			margin-bottom: 4px; } }
	&-phone {
		a {
			&:hover {
				text-decoration: none; } } }
	&-callback {
		a {
			font-size: 12px;
			text-transform: uppercase;
			padding: 5px 10px;
			border-radius: 15px;
			box-shadow: 0px 1px 1px #ccc;
			&:link, &:visited {
				background-color: $white;
				color: $accent; }
			&:hover {
				text-decoration: none;
				color: $link-color-hover;
				box-shadow: none; } } }
	&-profile-cart {
		text-align: right;
		margin-bottom: 10px;
		> div {
			display: inline-block;
			vertical-align: middle; } }
	&-profile {
		margin-right: 10px;
		padding-right: 10px;
		border-right: 1px $pink solid;
		a {
			display: block;
			.fa {
				color: $accent;
				background-color: $white;
				width: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				border-radius: 50%;
				box-shadow: 0px 1px 1px #ccc;
				margin-right: 3px; }
			&:hover {
				text-decoration: none;
				.fa {
					color: $link-color-hover;
					background-color: $white;
					box-shadow: none; } } } }
	&-cart {
		text-align: center;
		a {
			.fa {
				font-size: 20px;
				color: $accent;
				background-color: $white;
				width: 32px;
				line-height: 32px;
				margin-bottom: 4px;
				border-radius: 50%;
				box-shadow: 0px 1px 1px #ccc; }
			.cart-total {
				display: block;
				color: $white; }
			&:hover {
				text-decoration: none;
				.fa {
					color: $link-color-hover;
					box-shadow: none; } } } }

	&-search {
		position: relative;
		input {
			border: none;
			color: $default-font-color;
			padding-right: 25px;
			width: 100%; }
		button {
			position: absolute;
			background: none;
			border: none;
			right: 0;
			color: $accent; } } }

#main-menu {
	box-shadow: 0px 1px 3px #ccc;
	position: relative;
	z-index: 2;
	> div {
		> ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			li {
				position: relative; }
			> li {
				> a {
					text-transform: uppercase;
					display: block;
					padding-top: 2px;
					line-height: 30px;
					border-bottom: 2px transparent solid;
					font-size: 14px;
					color: $default-font-color;
					font-weight: 400;
					&:hover {
						text-decoration: none;
						border-bottom: 2px $accent solid; } }
				ul {
					display: none;
					position: absolute;
					z-index: 2;
					background-color: $white;
					box-shadow: 0px 1px 3px #ccc;
					padding: 10px 0; }
				> ul {
						li {
							padding: 0 15px;
							.fa {
								position: absolute;
								right: 15px;
								top: 50%;
								transform: translateY(-50%); }
							a {
								white-space: nowrap;
								display: block;
								padding: 5px 0;
								font-size: 14px; }
							&:hover {
								> ul {
									display: block;
									left: 95%;
									top: 0; } } } }
				&:hover {
					> a {
						border-bottom: 2px $accent solid; }
					> ul {
						display: block; } } } } } }

.owl-carousel {
	background: none;
	.owl-wrapper-outer {
		background: none;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-align: center; }
	.owl-item {
		text-align: center;
		.product-card {
			display: inline-block; } }
	.owl-nav, .owl-prev, .owl-next {
		position: absolute; }
	.owl-nav {
		width: 100%;
		top: 50%; }
	.owl-buttons {
		.owl-prev, .owl-next {
			opacity: 1;
			.fa {
				font-size: 25px; }
			&.disabled {
				opacity: .3;
				cursor: default; } }
		.owl-prev {
			left: 0; }
		.owl-next {
			right: 0; } }

	&.image-additional-carousel, &.product-info-mobile-images {
		.owl-buttons {
			div {
				opacity: 1; } } }
	&.product-info-mobile-images {
		.owl-pagination {
			top: 10px; } } }

.slideshow {
	margin-bottom: 15px; }

.product-card {
	margin-bottom: 20px;
	.image {
		border: 1px #ccc solid;
		margin-bottom: 7px;
		text-align: center;
		img {
			max-width: 100%; } }
	h3 {
		margin: 0;
		height: 55px;
		line-height: 1.4;
		font-size: 13px;
		a {
			color: $default-font-color;
			text-transform: uppercase; } }
	.stock, .price {
		text-align: center;
		margin-bottom: 5px; }
	.price {
		color: $accent;
		font-weight: 500;
		font-size: 15px;
		&-old {
			font-size: 12px;
			color: #8D8C8E;
			text-decoration: line-through;
			font-weight: 300; } }
	.buttons {
		display: table;
		width: 100%;
		box-shadow: 0px 1px 4px #ccc;
		border-radius: 5px;
		> a {
			display: table-cell;
			text-align: center;
			color: $white;
			background-color: $accent;
			font-size: 12px;
			height: 25px;
			vertical-align: middle;
			box-shadow: none;
			&:first-child {
				border-radius: 5px 0 0 5px;
				border-right: 1px $pink solid; }
			&:last-child {
				border-radius:  0 5px 5px 0;
				border-left: 1px $pink solid; }
			&:hover {
				text-decoration: none;
				background-color: $pink; } } } }

.product-card-grid {
	.product-card {
		.description {
			display: none; } } }

.product-card-list {
	.product-card {
		display: flex;
		justify-content: space-between;
		.image {
			width: 22%;
			padding: 2px;
			img {
				max-width: 100%; } }
		.name-description-stock {
			width: 52%;
			padding-top: 15px; }
		.price-buttons {
			width: 18%;
			padding-top: 15px;
			padding-right: 15px; }
		.description,
		.stock {
			display: block; }
		.description {
			margin-bottom: 5px;
			font-size: 1.3rem;
			color: #4D4D4E; }
		.stock {
			color: $accent; }
		.price {
			margin-bottom: 7px;
			> span {
				display: block;
				text-align: center; } }
		.buttons {
			display: block;
			box-shadow: none;
			a {
				display: block;
				border-radius: 5px;
				line-height: 25px;
				box-shadow: 0px 1px 4px #ccc;
				+ a {
					margin-top: 10px; } } } } }

.product-card-list {
	.product-card-block {
		width: 100%;
		float: none; } }

.module-product-block {
	margin-bottom: 20px;
	.product-card-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.product-card {
			width: 252px; } } }

.module-product-block {
	.product-card-grid.owl-carousel {
		display: block;
		.product-card {
			width: 240px; } } }

.module-block {
	margin-bottom: 15px; }
.breadcrumb {
	margin: 0;
	padding: 0;
	list-style: none;
	margin: 15px 0 17px;
	li {
		display: inline-block;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 400;
		color: $link-color;
		+ li {
			margin-left: 4px;
			&:before {
				content: '';
				font-family: fontawesome;
				margin-right: 4px; } }
		a {
			font-size: 12px;
			color: $default-font-color;
			font-weight: 300;
			&:hover {
				text-decoration: none;
				color: $accent; } }
		&:first-child {
			a {
				border-bottom: none; } } } }

#column-left {
	.module-product-block {
		.product-card-grid {
			display: block;
			.product-card {
				width: auto; } } }

	.product-card {
		h3 {
			height: auto; } } }

.filter-block {
	.filter {
		&-name {
			font-weight: 500;
			margin-bottom: 3px; }
		&-content {
			input[type="checkbox"] {
				margin-right: 5px; } }
		+ .filter {
			margin-top: 10px; } }
	.buttons {
		margin-top: 15px; } }

h2.h2-category-module {
	border: none;
	margin: 0; }

.sidebar-category-list {
	border-bottom: 1px #ccc solid;
	> li {
		> a {
			text-transform: uppercase;
			display: block;
			border-left: 2px transparent solid;
			padding-left: 8px;
			padding-bottom: 7px;
			padding-top: 7px;
			border-top: 1px #ccc solid;
			&:hover, &.active {
				border-left: 2px $accent solid;
				text-decoration: none;
				color: $default-font-color; } }
		ul {
			margin-bottom: 7px;
			margin-left: 15px;
			li {
				padding: 2px 0;
				a {
					&:before {
						content: "";
						font-family: fontawesome;
						color: $accent;
						margin-right: 5px; }
					&:hover, &.active {
						text-decoration: none;
						color: $accent; } } } } } }
.category-subcategory-list {
	ul {
		margin-bottom: 15px;
		li {
			padding: 2px 0;
			a {
				&:before {
					content: "";
					font-family: fontawesome;
					color: $accent;
					margin-right: 5px; }
				&:hover {
					text-decoration: none;
					color: $accent; } } } } }

.panel-manage-products {
	margin-bottom: 20px;
	padding: 10px 15px;
	border-bottom: 1px #ccc solid;
	.display-button {
		button {
			border: none;
			background: none;
			padding: 0;
			display: inline-block;
			vertical-align: middle;
			.fa {
				font-size: 23px;
				line-height: 1;
				color: $default-font-color; }
			+ button {
				margin-left: 7px; }
			&.active {
				.fa {
					color: $accent; } } } }
	.sort-limit {
		> * {
			display: inline-block;
			vertical-align: bottom;
			select {
				max-width: 150px; } }
		.limit {
			margin-left: 10px; } }
	.compare {
		text-align: right;
		a {
			display: inline-block;
			vertical-align: middle; } } }

ul.pagination {
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		display: inline-block;
		+ li {
			margin-left: 7px; }
		a, span {
			display: block;
			height: 25px;
			width: 25px;
			line-height: 22px;
			font-size: 12px;
			border: 1px solid $accent;
			border-radius: 50%;
			text-align: center; }
		span {
			background-color: #cecece;
			font-weight: 400;
			border: none;
			background: none; }
		a:hover {
			text-decoration: none;
			border-color: $accent;
			background-color: $accent;
			color: $white; } } }

.product-info {
	.main-image {
		margin-bottom: 15px;
		a {
			display: block;
			img {
				max-width: 100%; } } }
	.image-additional-item {
		a {
			display: block;
			text-align: center;
			img {
				border: 1px #ccc solid; }
			&.active {
				img {
					border-color: $accent; } } } }
	.find-cheaper {
		margin-bottom: 15px;
		a {
			color: $default-font-color;
			border-bottom: 1px dashed;
			font-size: 14px;
			&:hover {
				text-decoration: none;
				color: $accent; } } }
	.product-meta {
		margin-bottom: 15px;
		.line {
			span {
				margin-right: 5px;
				font-weight: 400; } } }
	.right-panel .price {
		margin-bottom: 20px;
		color: #333;
		font-weight: 500;
		font-size: 23px;
		&-old {
			font-size: 14px;
			color: $accent;
			text-decoration: line-through;
			font-weight: 300; } }
	.product-action-one {
		margin-bottom: 25px;
		> * {
			display: inline-block; }
		input {
			width: 50px;
			text-align: center; }
		button.plus,
		button.minus {
			border: none;
			background: none; } }

	.product-action-two {
		margin-bottom: 7px;
		* {
			margin: 7px; } }
	.tags {
		&-heading {
			font-weight: 500; }
		ul {
			li {
				&:before {
					content: '';
					font-family: fontawesome;
					color: $accent;
					margin-right: 5px;
					font-weight: 200; } } } }
	.product-info-mobile-images {
		margin-bottom: 20px; } }

.product-description {
	iframe {
		width: 100%!important; } }
.product-attribute {
	table {
		width: 100%;
		tr {
			td {
				width: 49%;
				padding: 5px 0;
				&:last-of-type {
					text-align: center; } }
			+ tr {
				border-top: 1px #ccc solid; } } } }
.product-review {
	.buttons {
		margin-top: 20px; } }
.reviews-list-block {
	margin-bottom: 20px;
	.reviews-list {
		&-item {
			margin-bottom: 20px;
			.top {
				display: table;
				width: 100%;
				margin-bottom: 10px;
				> div {
					display: table-cell;
					vertical-align: middle;
					width: 50%; }
				.author-date {
					text-align: right;
					padding-right: 10px;
					span {
						font-weight: 500; } }
				.rating {
					color: $accent; } }
			.message-wrap {
				text-align: center;
				.message {
					font-style: italic;
					display: inline-block;
					padding: 15px 20px;
					position: relative;
					&:before,
					&:after, {
						position: absolute;
						font-family: fontawesome;
						color: #666;
						font-size: 13px; }
					&:before {
						content: '';
						top: 0;
						left: 0; }
					&:after {
						content: '';
						bottom: 0;
						right: 0; } } } } } }
.panel {
	margin-bottom: 20px; }

.seotext {
	margin-bottom: 20px; }

.simplecheckout-cart {
	margin-bottom: 20px;
	&-products {
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			border-bottom: 1px #ccc solid;
			padding-bottom: 15px;
			> * {
				padding: 5px; }
			.product {
				width: 50%;
				.image {
					float: left;
					img {
						border: 1px #ccc solid; } }
				.meta {
					margin-left: 120px;
					.name {
						margin-bottom: 5px;
						a {
							font-weight: 500;
							color: $default-font-color;
							text-transform: uppercase; } }
					.model {
						margin-bottom: 10px;
						font-size: 13px; }
					.price {
						color: $accent;
						font-size: 16px;
						span {
							color: #666;
							font-size: 13px; } } } }
			.quantity,
			.total,
			.remove {
				text-align: center; }
			.quantity {
				width: 30%;
				white-space: nowrap;
				button {
					background: none;
					border: none; }
				input {
					width: 40px;
					text-align: center; } }
			.total {
				width: 15%;
				color: $accent;
				font-weight: 500;
				font-size: 17px;
				white-space: nowrap;
				span {
					color: #666;
					font-weight: 300;
					font-size: 13px; } }
			.remove {
				width: 5%;
				button {
					background: none;
					border: none;
					.fa {
						font-size: 16px; } } } } }
	&-totals {
		display: table;
		width: 100%;
		> div {
			display: table-row;
			> span {
				display: table-cell;
				padding: 5px;
				&:first-child {
					text-align: right;
					width: 85.5%;
					font-weight: 400; }
				&:last-of-type {
					white-space: nowrap; } } }
		#total_total {
			font-size: 17px;
			span {
				&:last-of-type {
					color: $accent;
					font-size: 18px;
					font-weight: 600; } } } } }

#simplecheckout_shipping_address,
#simplecheckout_comment {
	margin-top: 15px; }
#agreement_checkbox {
	margin-bottom: 10px; }

.callback-form,
.fastorder-form,
.popuplogin-form,
.cheaper-form,
.flycart,
.compare-info {
	padding: 15px; }
.compare-info {
	padding-top: 30px;
	text-align: center; }
.wrapper-popup-form {
	.buttons {
		text-align: center;
		margin-top: 15px; } }

.flycart {
	&-heading {
		h2 {
			margin-bottom: 15px;
			border: none;
			text-align: center; }
		p {
			margin: 0;
			margin-bottom: 15px;
			b {
				border: 1px solid #ccc;
				background-color: $white;
				display: inline-block;
				width: 25px;
				height: 25px;
				text-align: center;
				line-height: 22px;
				border-radius: 50%; } } }
	.products-item {
		border-top: 1px #ccc solid;
		padding-top: 10px;
		margin-bottom: 10px;
		.image {
			text-align: center;
			img {
				max-width: 100%;
				border: 1px #ccc solid; } }
		.name {
			margin-bottom: 2px;
			a {
				color: $default-font-color;
				text-transform: uppercase;
				font-weight: 400; } }
		.stock,
		.quantity {
			font-size: 12px;
			color: $accent; }
		.total {
			white-space: nowrap; } }
	&-buttons {
		border-top: 1px #ccc solid;
		padding-top: 15px;
		display: flex;
		justify-content: space-between; } }
.fastorder-form,
.cheaper-form {
	h2 {
		margin-top: 10px; } }

.search-parameters {
	margin-bottom: 15px; }

.contact-page-contacts {
	margin-bottom: 20px;
	.line {
		.icon {
			float: left;
			.fa {
				font-size: 16px;
				color: $white;
				background-color: $accent;
				width: 25px;
				line-height: 25px;
				text-align: center;
				border-radius: 50%; }
			+ .content {
				margin-left: 30px; } }
		+ .line {
			margin-top: 15px; } } }

.footer-top {
	margin-top: 15px;
	background-color: $accent;
	color: $white;
	padding: 15px 15px 0;
	h4 {
		margin: 0;
		font-weight: 300;
		text-transform: uppercase;
		font-size: 14px;
		border-bottom: 1px solid #ccc;
		+ ul {
			margin-top: 5px; } }
	a {
		&:link, &:visited {
			color: $white; } }
	.fa {
		color: $accent;
		background-color: $white;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		border-radius: 50%; } }
.table-responsive {
	overflow-x: scroll; }
.compare-table {
	img {
		min-width: 120px; } }
.footer-bottom {
	padding: 15px 0;
	text-align: right;
	> div > div {
		display: inline-block; }
	.icons {
		height: 30px;
		margin-left: 15px;
		img {
			max-width: 100%;
			max-height: 100%; } } }

.mfp-ajax-holder .mfp-content {
	max-width: 600px;
	width: 100%;
	background-color: #fff; }

.scroll-to-top {
	position: fixed;
	bottom: 10px;
	right: 15px;
	display: none;
	z-index: 1;
	border: 1px #333 solid;
	border-radius: 50%;
	padding: 4px 10px;
	background-color: $white;
	cursor: pointer;
	&:hover {
		background-color: $accent;
		color: $white; } }

.compare-widget {
	a {
		position: fixed;
		text-decoration: none;
		right: 0;
		top: 25%;
		background-color: #fff;
		opacity: .6;
		box-shadow: 0 1px 3px #cecece;
		border: 1px $accent solid;
		border-right: none;
		padding: 5px 7px;
		z-index: 1;
		.fa, span {
			display: block;
			text-align: center; }
		.fa {
			margin-bottom: 7px;
			font-size: 20px; }
		&:hover {
			opacity: 1; } } }
.text-checkout {
	margin-bottom: 20px; }

.simplecheckout-cart-discounts-totals {
	display: table;
	width: 100%;
	> div {
		width: 50%;
		display: table-cell;
		vertical-align: middle; }
	.simplecheckout-cart-discounts {
		> span {
			display: inline-block;
			padding: 2px 5px;
			background-color: rgba(0,0,0, .1);
			.fa {
				color: $accent; } } } }
@import "media"; // Всегда в конце
